import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  TextInput,
  Button,
  StyleSheet,
  ActivityIndicator,
  Switch,
  TouchableOpacity,
  ScrollView,
  useWindowDimensions,
} from "react-native";

import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import DateTimePicker from "@react-native-community/datetimepicker";
import es from "date-fns/locale/es";
import moment from "moment";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import tw from "twrnc";
import MaskedInput from "react-input-mask";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";

import ResultadoModal from "../components/ResultadoModal";
import MensajeError from "../components/MensajeError";
import {
  editarColaborador,
  nuevoColaborador,
  eliminarColaborador,
} from "../services/apiClient";
import ColaboradorEmpresa from "../components/Colaboradores/ColaboradorEmpresa";
import { awesomeAlertAtom } from "../store/store";
import { FontAwesome5 } from "@expo/vector-icons";
import EditarImagen from "../components/EditarImagen";
import { useNavigation } from "@react-navigation/native";
import { Colores } from "../constants/colores";
import { esWeb } from "../utils/plataforma";
import WebDatePicker from "../components/WebDatePicker";

function ColaboradorEditarForm({
  colaborador,
  setawesomeAlert,
  resetAwesomeAlert,
}) {
  const navigator = useNavigation();
  const [dataColaborador, setdataColaborador] = useState(colaborador);
  const [showDateTimePicker, setshowDateTimePicker] = useState(false);
  const [relacion, setRelacion] = useState({
    empresaActual: dataColaborador?.empresa_actual.id,
    dptoActual: dataColaborador?.departamento_actual.id,
    puestoActual: dataColaborador?.puesto_actual.id,
  });

  const colaboradorFechaDeNacimiento = moment(
    dataColaborador?.fechaNacimiento
  )._d;
  const colaboradorAltaAfip = dataColaborador?.altaAfip ? moment(dataColaborador?.altaAfip)._d : null;
  const [foto, setfoto] = useState();
  const [activo, setactivo] = useState(dataColaborador?.activo);
  const [fechaNacimiento, setfechaNacimiento] = useState(
    colaboradorFechaDeNacimiento
  );
  const [altaAfip, setAltaFip] = useState(colaboradorAltaAfip);
  const [colaboradorId, setcolaboradorId] = useState();
  const queryClient = useQueryClient();
  const { width } = useWindowDimensions();



  let valoresInicialesForm = {
    nombre: dataColaborador?.nombre,
    apellido: dataColaborador?.apellido,
    dni: dataColaborador?.dni,
    emailPersonal: dataColaborador?.emailPersonal,
    telPersonal: dataColaborador?.telPersonal,
  };

  const toggleActivoSwitch = () => setactivo((previousState) => !previousState);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: valoresInicialesForm,
  });
  useEffect(() => {
    if (colaborador) {
      setdataColaborador(colaborador);
      setValue("nombre", colaborador?.nombre);
      setValue("apellido", colaborador?.apellido);
      setValue("dni", colaborador?.dni);
      setValue("direccion", colaborador?.direccion);
      setValue("emailCorporativo", colaborador?.emailCorporativo);
      setValue("telCorporativo", colaborador?.telCorporativo);
      setValue("emailPersonal", colaborador?.emailPersonal);
      setValue("telPersonal", colaborador?.telPersonal);
      const fecha = moment(colaborador?.fechaNacimiento)._d;
      const fechaAltaAfip = colaborador?.altaAfip ? moment(colaborador?.altaAfip)._d : null;
      setfechaNacimiento(fecha);
      setAltaFip(fechaAltaAfip);
      setRelacion({
        empresaActual: colaborador?.empresa_actual.id,
        dptoActual: colaborador?.departamento_actual.id,
        puestoActual: colaborador?.puesto_actual.id,
      });
    }
  }, [colaborador]);

  const confirmar = (colaboradorId) => {
    setcolaboradorId(colaboradorId);
    setawesomeAlert({
      show: true,
      showProgress: eliminarMutate.isLoading,
      title: "Atención",
      message: "Esta seguro de desea dar de baja al usuario?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmText: "Eliminar",
      confirmButtonColor: "#DD6B55",
      onConfirmPressed: () => {
        eliminarMutate.mutate(colaboradorId);
      },
      onDismiss: () => {
        resetAwesomeAlert();
      },
    });
  };

  const editarColaboradorMutate = useMutation(editarColaborador, {
    onSuccess: async (response) => {
      if (response.data.ok) {
        setawesomeAlert({
          show: true,
          showProgress: false,
          title: "Exito!",
          title: null,
          message: null,
          showCancelButton: false,
          showConfirmButton: true,
          confirmText: "Aceptar",
          confirmButtonColor: "green",
          customView: (
            <ResultadoModal success={true} message="Colaborador editado" />
          ),
          onConfirmPressed: () => {
            resetAwesomeAlert();
            navigator.navigate("Administrar Colaboradores");
          },
          onDismiss: () => {
            resetAwesomeAlert();
            navigator.navigate("Administrar Colaboradores");
          },
        });
        queryClient.invalidateQueries("colaboradores");
      }
    },
    onError: async (error) => {
      let mensajeErrores = "";
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        mensajeErrores = mensajeErrores + " \n" + value;
      }
      //TODO: mostrar errores en campos
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: <ResultadoModal success={false} message={mensajeErrores} />,
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
  });

  const formData = new FormData();

  const onSubmit = (data) => {
    // formData.append("unica", 1);
    if (foto) {
      formData.append("foto", foto?.file);
    }
    if (fechaNacimiento) {
      const fechaFormatoBD = moment(fechaNacimiento).format("yyyy-MM-DD");
      formData.append("fechaNacimiento", fechaFormatoBD);
    }

    if (altaAfip) {
      const altaAfipFormatoBD = moment(altaAfip).format("yyyy-MM-DD");
      formData.append("altaAfip", altaAfipFormatoBD);
    }
    //se cargan el resto de campos del formulario en formData
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        if (data[key] && data[key] !== "") {
          formData.append(key, data[key]);
        }
      }
    }

    // formData.delete('empresaActual')

    editarColaboradorMutate.mutate({
      colaboradorId: dataColaborador?.id,
      formData,
    });
  };

  const eliminarMutate = useMutation(eliminarColaborador, {
    onSuccess: async (response) => {
      console.log("colaborador eliminado", response.data);
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Exito!",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal success={true} message={response.data.message} />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
          navigator.navigate("Administrar Colaboradores");
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
      queryClient.invalidateQueries("colaboradores");
    },
    onError: async (error) => {
      let mensajeErrores = "";
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        mensajeErrores = mensajeErrores + " \n" + value;
      }

      //setmostrarAlerta(false)
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: <ResultadoModal success={false} message={mensajeErrores} />,
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
    onSettled: (data) => {
      if (data.data.ok) {
        setmostrarAlerta(false);
      }
    },
  });


  return (
    <>
      <ScrollView>
        <View style={[tw`bg-white`, { borderRadius: width >= 768 ? 20 : 0 }]}>
          {width >= 768 && (
            <Text style={tw`text-lg font-semibold my-5 ml-10`}>
              Editar Colaborador
            </Text>
          )}

          <View style={tw`flex-row px-7`}>
            <View style={tw`items-center justify-center flex-1`}>
              <EditarImagen formData={formData} modelo={colaborador} />

              <TouchableOpacity
                style={[
                  tw`p-2 justify-center items-center absolute bottom-4 right-4 rounded-full`,
                  { backgroundColor: Colores.primary },
                ]}
              >
                <FontAwesome5 name="pen" size={11} color={"#FFF"} />
              </TouchableOpacity>
            </View>
            <View style={tw`flex-2 pl-7 pt-5`}>
              <Text
                style={tw`text-lg font-semibold`}
              >{`${dataColaborador?.nombre} ${dataColaborador?.apellido}`}</Text>
              <Text style={tw``}>
                <FontAwesome5
                  name="briefcase"
                  size={11}
                  color="black"
                  style={tw`mr-2`}
                />
                {dataColaborador?.puesto_actual.nombre}
              </Text>
              <Text style={tw``}>
                <FontAwesome5
                  name="building"
                  size={11}
                  color="black"
                  style={tw`mr-2`}
                />
                {dataColaborador?.empresa_actual.nombre}
              </Text>
              <Controller
                control={control}
                defaultValue={activo}
                name="activo"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={tw`flex-row p-1 mt-6 mb-5`}>
                    <Switch
                      trackColor={{ false: "#767577", true: "#81b0ff" }}
                      thumbColor={value ? "#f5dd4b" : "#f4f3f4"}
                      ios_backgroundColor="#3e3e3e"
                      onValueChange={(value) => {
                        toggleActivoSwitch;
                        onChange(value ? 1 : 0);
                      }}
                      value={value}
                    />
                    <Text style={tw`ml-2 `}>Activo</Text>
                  </View>
                )}
              />
            </View>
          </View>

          <View
            style={[
              tw``,
              { borderBottomWidth: 4, borderBottomColor: Colores.primaryLight },
            ]}
          />
          <View style={tw`px-5 z-50`}>
            <Text style={[tw`text-lg mb-2 mt-4`, { color: Colores.primary }]}>
              Datos Personales
            </Text>
            <View
              style={[
                tw``,
                {
                  borderBottomWidth: 1,
                  borderBottomColor: Colores.primaryLight,
                },
              ]}
            />
            <Controller
              control={control}
              rules={{
                required: "Ingrese el nombre",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[
                    tw` mt-4 w-full px-3 py-2 text-gray-700 border`,
                    { borderRadius: 50, borderColor: Colores.primary },
                  ]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  placeholder="Nombre"
                  value={value}
                  placeholderTextColor={Colores.primary}
                />
              )}
              name="nombre"
            />
            {errors?.nombre && (
              <MensajeError message={errors.nombre?.message} />
            )}
            <Controller
              control={control}
              rules={{
                required: "Ingrese el apellido",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[
                    tw` mt-5 w-full px-3 py-2 text-gray-700 border`,
                    { borderRadius: 50, borderColor: Colores.primary },
                  ]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="Apellido"
                  value={value}
                  placeholderTextColor={Colores.primary}
                />
              )}
              name="apellido"
            />
            {errors?.apellido && (
              <MensajeError message={errors.apellido?.message} />
            )}

            <Controller
              control={control}
              rules={{
                required: "Ingrese el dni",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[
                    tw` mt-5 w-full px-3 py-2 text-gray-700 border`,
                    { borderRadius: 50, borderColor: Colores.primary },
                  ]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="DNI"
                  value={value}
                  placeholderTextColor={Colores.primary}
                />
              )}
              name="dni"
            />
            {errors?.dni && <MensajeError message={errors.dni?.message} />}

            <Controller
              control={control}
              rules={{
                required: false,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[
                    tw` mt-5 w-full px-3 py-2 text-gray-700 border`,
                    { borderRadius: 50, borderColor: Colores.primary },
                  ]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="Direccion"
                  value={value}
                  placeholderTextColor={Colores.primary}
                />
              )}
              name="direccion"
            />
            {errors?.direccion && (
              <MensajeError message={errors.direccion?.message} />
            )}

            <View style={tw`mt-5 z-10 flex-row items-center`}>
              <View style={tw`mr-3`}>
                <Text style={{ color: Colores.primary }}>
                  Fecha de nacimiento
                </Text>
              </View>
              <View style={tw`mr-2`}>
                {esWeb() ? (
                  <WebDatePicker
                    key="fecha-nacimiento"
                    selected={fechaNacimiento}
                    minDate={moment().subtract(90, "years")._d}
                    maxDate={moment().subtract(18, "years")._d}
                    onChange={(date) => {
                      setfechaNacimiento(date);
                    }}
                  />
                ) : (
                  <View style={tw`flex flex-row items-center justify-start`}>
                    {showDateTimePicker ? (
                      <DateTimePicker
                        value={fechaNacimiento}
                        onChange={(event, date) => {
                          if (event.type == "set") {
                            setfechaNacimiento(date);
                            setshowDateTimePicker(false);
                          } else {
                            //cancel button clicked
                            setshowDateTimePicker(false);
                          }
                        }}
                        minimunDate={moment().subtract(90, "years")._d}
                        maximunDate={moment().subtract(18, "years")._d}
                      />
                    ) : null}
                    <TouchableOpacity
                      onPress={() => setshowDateTimePicker(!showDateTimePicker)}
                      style={[
                        tw`flex flex-row items-center justify-center border-2 border-indigo-400 px-4 py-2`,
                        { backgroundColor: "white", borderRadius: 15 },
                      ]}
                    >
                      <AntDesign
                        name="calendar"
                        size={20}
                        color={Colores.primary}
                      />
                      <Text style={tw`ml-2 text-center`}>
                        {moment(fechaNacimiento).format("MM/YYYY")}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>

            <View style={tw`mt-5 z-10 flex-row items-center`}>
              <View style={tw`mr-3 flex flex-col`}>
                <Text style={{ color: Colores.primary }}>
                  Fecha de alta en AFIP
                </Text>
                {!dataColaborador?.altaAfip ? (
                  <View
                    style={tw`bg-yellow-200 rounded-md border border-yellow-500 p-4`}
                  >
                    <Text>Debes indicar una fecha de alta en AFIP</Text>
                  </View>
                ) : null}
              </View>
              <View style={tw`mr-2`}>
                {esWeb() ? (
                  <WebDatePicker
                    key="fecha-altafip"
                    selected={altaAfip ? altaAfip : null}
                    minDate={moment().subtract(60, "years")._d}
                    maxDate={moment()._d}
                    onChange={(date) => {
                      setAltaFip(date);
                    }}
                  />
                ) : (
                  <View style={tw`flex flex-row items-center justify-start`}>
                    {showDateTimePicker ? (
                      <DateTimePicker
                        value={altaAfip}
                        onChange={(event, date) => {
                          if (event.type == "set") {
                            setAltaFip(date);
                            setshowDateTimePicker(false);
                          } else {
                            //cancel button clicked
                            setshowDateTimePicker(false);
                          }
                        }}
                        minimunDate={moment().subtract(60, "years")._d}
                        maximunDate={moment()._d}
                      />
                    ) : null}
                    <TouchableOpacity
                      onPress={() => setshowDateTimePicker(!showDateTimePicker)}
                      style={[
                        tw`flex flex-row items-center justify-center border-2 border-indigo-400 px-4 py-2`,
                        { backgroundColor: "white", borderRadius: 15 },
                      ]}
                    >
                      <AntDesign
                        name="calendar"
                        size={20}
                        color={Colores.primary}
                      />
                      <Text style={tw`ml-2 text-center`}>
                        {moment(altaAfip).format("MM/YYYY")}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>
          </View>
          <View
            style={[
              tw`mt-5`,
              { borderBottomWidth: 4, borderBottomColor: Colores.primaryLight },
            ]}
          />
          <View style={tw`px-5`}>
            <Text style={[tw`text-lg mb-2 mt-4`, { color: Colores.primary }]}>
              Contacto
            </Text>
            <View
              style={[
                tw``,
                {
                  borderBottomWidth: 1,
                  borderBottomColor: Colores.primaryLight,
                },
              ]}
            />

            <Controller
              control={control}
              rules={
                {
                  // required: "Ingrese un email corporativo",
                }
              }
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[
                    tw` mt-4 w-full px-3 py-2 text-gray-700 border`,
                    { borderRadius: 50, borderColor: Colores.primary },
                  ]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="Email Corporativo"
                  placeholderTextColor={Colores.primary}
                  value={value}
                />
              )}
              name="emailCorporativo"
            />
            {errors?.emailCorporativo && (
              <MensajeError message={errors.emailCorporativo?.message} />
            )}

            <Controller
              name="telCorporativo"
              control={control}
              defaultValue=""
              rules={{
                required: false,
              }}
              render={({ field }) => (
                <MaskedInput
                  mask="(999) 9 999999"
                  maskChar=""
                  value={field.value}
                  onChange={field.onChange}
                >
                  {(inputProps) => (
                    <TextInput
                      {...inputProps}
                      type="text"
                      style={[
                        tw` mt-4 w-full px-3 py-2 text-gray-700 border`,
                        { borderRadius: 50, borderColor: Colores.primary },
                      ]}
                      placeholder="Telefono Corp"
                      placeholderTextColor={Colores.primary}
                    />
                  )}
                </MaskedInput>
              )}
            />

            <View style={tw`items-center justify-center`}>
              <View
                style={[
                  tw`mt-4 px-20`,
                  {
                    borderBottomWidth: 1,
                    borderBottomColor: Colores.primaryLight,
                  },
                ]}
              />
            </View>

            <Controller
              control={control}
              rules={{
                required: "Ingrese un email personal",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[
                    tw` mt-4 w-full px-3 py-2 text-gray-700 border`,
                    { borderRadius: 50, borderColor: Colores.primary },
                  ]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="Email personal"
                  value={value}
                  placeholderTextColor={Colores.primary}
                />
              )}
              name="emailPersonal"
            />
            {errors?.emailPersonal && (
              <MensajeError message={errors.emailPersonal?.message} />
            )}
            <Controller
              control={control}
              rules={{
                required: false,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[
                    tw` mt-4 w-full px-3 py-2 text-gray-700 border`,
                    { borderRadius: 50, borderColor: Colores.primary },
                  ]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="Tel personal"
                  value={value}
                  placeholderTextColor={Colores.primary}
                />
              )}
              name="telPersonal"
            />
            {errors?.telPersonal && (
              <MensajeError message={errors.telPersonal?.message} />
            )}
          </View>
          {editarColaboradorMutate.isLoading ? (
            <ActivityIndicator
              size="small"
              color={Colores.primary}
              style={tw`my-5`}
            />
          ) : (
            <View style={tw`items-center justify-center`}>
              <TouchableOpacity
                style={[
                  tw`my-5 px-20 py-2.5 shadow-md`,
                  { backgroundColor: Colores.primary, borderRadius: 16 },
                ]}
                onPress={handleSubmit(onSubmit)}
              >
                <Text style={tw`text-white text-center`}>Guardar</Text>
              </TouchableOpacity>
            </View>
          )}
          <View style={tw`px-5`}>
            <ColaboradorEmpresa colaborador={colaborador} relacion={relacion} />
          </View>
          <View style={tw`items-center justify-center mb-10`}>
            <TouchableOpacity onPress={() => confirmar(dataColaborador?.id)}>
              <Text style={tw`text-red-500`}>Desvincular Colaborador</Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </>
  );
}

export default ColaboradorEditarForm;
