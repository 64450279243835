import React, { useEffect, useState } from "react";
import { View, Text, TextInput } from "react-native";
import tw from "twrnc";
import * as DocumentPicker from "expo-document-picker";
import { TouchableOpacity } from "react-native";
import { Colores } from "../../constants/colores";
import { Controller, useFormContext } from "react-hook-form";
import ArchivoItem from "./ArchivoItem";
import ColaboradorItem from "./ColaboradorItem";
import ContainerStep from "./ContainerStep";
import ObservacionesItem from "./ObservacionesItem";
import ArchivoRemotoItem from "./ArchivoItemRemoto";

const allowedTypes = [
  "application/pdf",
  "image/jpeg",
  "image/png",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const ContentStep2 = ({ item, formData, currentStep, setmostrarAlert }) => {
  const ctx = useFormContext();
  const {
    control,
    formState: { errors },
  } = ctx;

  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFilePick = async () => {
    try {
      const results = await DocumentPicker.getDocumentAsync({
        type: allowedTypes,
        multiple: true,
      });

      if (results.type === "success") {
        const allowed = Object.entries(results.output)
          .filter(([key, file]) => allowedTypes.includes(file.type))
          .map(([key, file]) => file);
        setSelectedFiles((prevFiles) => [...prevFiles, ...allowed]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleFileRemove = (indexToRemove) => {
    const removedFile = selectedFiles[indexToRemove];
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedFiles(updatedFiles);
    formData.delete(`archivos[${indexToRemove}]`);
  };

  const handleRemoteFileRemove = (indexToRemove) => {
    const removedFile = item.archivos[indexToRemove];
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedFiles(updatedFiles);
    formData.delete(`archivos[${indexToRemove}]`);
  };

  useEffect(() => {
    formData.delete("archivos");
    selectedFiles.forEach((file, index) => {
      if (!formData.get(`archivos[${index}]`)) {
        formData.append(`archivos[${index}]`, file);
      }
    });
  }, [selectedFiles]);

  return (
    <ContainerStep step={2} currentStep={currentStep}>
      <View style={tw`flex flex-row items-start gap-4`}>
        <View
          style={tw`max-w-[350px] items-center border border-gray-200 rounded-md p-4 gap-3`}
        >
          <Text style={tw`self-start font-semibold`}>Archivos actuales</Text>
          {item?.archivos
            ? item.archivos.map((archivo, index) => (
                <ArchivoRemotoItem
                  file={archivo}
                  key={archivo.id}
                  index={index}
                  setmostrarAlert={setmostrarAlert}
                />
              ))
            : null}
          <Text style={tw`self-start font-semibold`}>
            Adjuntar archivos (opcional)
          </Text>
          {selectedFiles.map((file, index) => (
            <ArchivoItem
              key={index}
              index={index}
              file={file}
              handleFileRemove={handleFileRemove}
            />
          ))}

          <TouchableOpacity
            style={[
              tw`max-w-[200px] py-2 px-4 rounded-lg`,
              { backgroundColor: Colores.primary },
            ]}
            onPress={handleFilePick}
          >
            <Text style={tw`text-white text-md font-bold text-center`}>
              + Agregar archivo
            </Text>
          </TouchableOpacity>
        </View>

        <View style={tw`flex-1 border border-gray-200 rounded-md p-4 gap-4`}>
          {item?.observaciones ? (
            <ObservacionesItem observaciones={item.observaciones} />
          ) : null}
          <Text style={tw`font-bold mt-4`}>Observaciones (opcional)</Text>

          <ColaboradorItem />
          <Controller
            control={control}
            rules={{
              minLength: {
                value: 2,
                message: "Al menos 3 caracteres",
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <TextInput
                  style={tw`min-h-[100px] mt-3 w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded`}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  placeholder="Dejar observación"
                  value={value}
                  multiline={true}
                />
                {errors?.observacion && (
                  <Text>Error: {errors.observacion?.message}</Text>
                )}
              </>
            )}
            name="observacion"
            defaultValue=""
          />
        </View>
      </View>
    </ContainerStep>
  );
};

export default ContentStep2;
