import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import tw from "twrnc";
import ContainerStep from "./ContainerStep";
import { useFormContext } from "react-hook-form";
import ArchivoItem from "./ArchivoItem";
import TipoItemSeleccionado from "./TipoItemSeleccionado";
import EstadoItem from "./EstadoItem";
import ColaboradorItem from "./ColaboradorItem";
import TituloItem from "./TituloItem";
import AsignadoA from "./AsignadoA";
import AsignadoPor from "./AsignadoPor";
import FechaAsignacion from "./FechaAsignacion";
import FechaCaduca from "./FechaCaduca";
import Cantidad from "./Cantidad";
import DescripcionItem from "./DescripcionItem";
import ObservacionesItem from "./ObservacionesItem";

const ContentStep4 = ({ item, formData, currentStep }) => {
  const ctx = useFormContext();
  const { watch } = ctx;

  const {
    titulo,
    cantidadEntregada,
    fechaCaduca,
    tipo_id,
    caduca,
    descripcion,
    observacion,
    conformeDevolucion,
    conformeRecepcion,
    requiereConformidadRecepcion,
    asignado_id,
  } = watch();

  console.log("conforme recepcion", conformeRecepcion)
  console.log(
    "archivos",
    [...formData.entries()].filter(([key, value]) => key.includes("archivos["))
  );

  const ArchivosLocales = () => {
    const archivos = [...formData.entries()].filter(([key, value]) =>
      key.includes("archivos[")
    );
    return archivos.map(([key, file]) => (
      <ArchivoItem key={key} index={key} file={file} />
    ));
  };
  return (
    <ContainerStep step={4} currentStep={currentStep}>
      <View style={tw`flex flex-col gap-4`}>
        <Text style={tw`font-bold text-xl`}>Resumen del Activo</Text>
        <View style={tw`flex flex-row items-start gap-4`}>
          <View
            style={tw`max-w-[350px] flex flex-col gap-4 border border-gray-200 rounded-md p-4`}
          >
            <TipoItemSeleccionado tipoId={tipo_id} />
            <TituloItem titulo={titulo}>
              <EstadoItem
                item={{
                  requiereConformidadRecepcion,
                  conformeDevolucion,
                  conformeRecepcion,
                  caduca,
                  fechaCaduca,
                }}
              />
            </TituloItem>

            <AsignadoA colaborador={asignado_id} />

            <AsignadoPor colaborador={item?.asigno} />

            <FechaAsignacion />

            <FechaCaduca fecha={fechaCaduca} />

            <Cantidad cantidad={cantidadEntregada} />

            <Text style={tw`text-xl font-semibold`}>Archivos</Text>
            <ArchivosLocales />
          </View>

          <View
            style={tw`flex-1 flex flex-col gap-4 border border-gray-200 rounded-md p-4`}
          >
            <DescripcionItem texto={descripcion} />

            <ObservacionesItem observaciones={observacion} />
            {item?.observaciones ? (
              <ObservacionesItem observaciones={item.observaciones} />
            ) : null}
          </View>
        </View>
      </View>
    </ContainerStep>
  );
};

export default ContentStep4;
