import { useAtom } from "jotai";
import React, { useState } from "react";
import {
  Modal,
  Text,
  View,
  Image,
  useWindowDimensions,
  FlatList,
  TextInput,
  TouchableOpacity,
  Switch,
  ActivityIndicator,
} from "react-native";
import { useMutation, useQuery, useQueryClient } from "react-query";
import tw from "twrnc";
import { nuevoAviso } from "../../services/apiClient";
import {
  empresasSeleccionadasAtom,
  idsEmpresasAtom,
  userAtom,
} from "../../store/store";
import { Ionicons } from "@expo/vector-icons";
import { Colores } from "../../constants/colores";
import * as ImagePicker from "expo-image-picker";
import { useForm, Controller } from "react-hook-form";
import MensajeError from "../MensajeError";
import { MultiSelectEmpresas } from "../MultiSelect";
import { AntDesign } from "@expo/vector-icons";
import uuid from "react-native-uuid";
import { validateURL } from "../../utils/generales";

const CrearAvisoModalDesktop = ({
  modalVisibleAvisoDesktop,
  setModalVisibleAvisoDesktop,
  navigation,
}) => {
  const [foto, setfoto] = useState();
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowDimensions();
  const [, setEmpresasSeleccionadas] = useAtom(empresasSeleccionadasAtom);
  const [idsEmpresas, setidsEmpresas] = useAtom(idsEmpresasAtom);
  const [esTemporal, setesTemporal] = useState(0);
  const [comentariosActivo, setcomentariosActivo] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [sinImagen, setSinImagen] = useState(false);
  const queryClient = useQueryClient();
  const [user, setUser] = useAtom(userAtom);
  const [contadorImagenes, setContadorImagenes] = useState(0);

  const pickImages = async () => {
    setIsLoading(true);
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsMultipleSelection: true,
      allowsEditing: true,
      aspect: [1, 1],
      selectionLimit: 10,
      quality: 0.5,
    });
    setIsLoading(false);
    if (!result.cancelled) {
      // Valida el tamaño del archivo
      const isSizeValid = await Promise.all(
        result.selected.map(async (image) => {
          try {
            let size;
  
            if (typeof image.uri === 'string' && image.uri.startsWith('data:')) {
              // Web environment
              size = image.uri.length;
            } else {
              // Native environment
              const fileInfo = await FileSystem.getInfoAsync(image.uri);
              size = fileInfo.size;
            }
  
            const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
            return size <= maxSizeInBytes;
          } catch (error) {
            console.log('Error al intentar obtener informacion del archivo', error);
            return false;
          }
        })
      );
  
      if (!isSizeValid.every((isValid) => isValid)) {
        alert('El tamaño maximo permitido por imagen es de 2 MB.');
        return;
      }
      setImages(result.selected);
    }
    if (result.selected) {
      const arrImg = [];
      await Promise.all(
        result?.selected.map(async (imgg) => {
          const typeImg = imgg.uri.match(/[^:/]\w+(?=;|,)/)[0];
          if (typeImg === "png" || typeImg === "jpeg" || typeImg === "jpg") {
            const hola = await fetch(imgg?.uri);
            const blob = await hola.blob();
            const uurl = hola.url;
            arrImg.push({
              uri: uurl,
              file: blob,
              id: uuid.v4(),
            });
          }
        })
      )
        .then(() => {
          setImages(arrImg);
        })
        .catch((e) => {
          // setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const alertInitialState = {
    show: false,
    title: "",
    message: "",
    showConfirmButton: false,
  };
  const [mostrarAlert, setmostrarAlert] = useState(alertInitialState);

  const toggleEsTemporalSwitch = () =>
    setesTemporal((previousState) => !previousState);

  const toggleComentariosActivoSwitch = () =>
    setcomentariosActivo((previousState) => !previousState);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const resetForm = () => {
    //reset form
    reset({ titulo: "", texto: "", url: "" });
    setfoto();
    setesTemporal(0);
    setcomentariosActivo(0);
    setidsEmpresas();
    //resetear multiselect
    setEmpresasSeleccionadas([]);
    setModalVisibleAvisoDesktop(false);
  };

  const nuevoAvisoMutate = useMutation(nuevoAviso, {
    onSuccess: async (response) => {
      resetForm();
      queryClient.invalidateQueries("avisos");
      queryClient.invalidateQueries("avisosCompletos");
      queryClient.invalidateQueries("notificaciones");
    },
    onError: async (error) => {
      console.log(error.response.data);
      //TODO: mostrar errores en campos
      setmostrarAlert({
        show: true,
        customView: <ResultadoModal success={false} message="Hubo un error" />,
      });
    },
  });

  const limpiarRedirigir = () => {
    navigation.goBack();
    setImages([]);
    setSinImagen(false);
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("unica", 1);
    if (images?.length > 0) {
      images.forEach((ig) => {
        formData.append("foto[]", ig.file);
      });
    }
    if (idsEmpresas) {
      formData.append("empresas", idsEmpresas);
    }
    //se cargan el resto de campos del formulario en formData
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        if (data[key] && data[key] !== "") {
          formData.append(key, data[key]);
        }
      }
    }

    // console.log(formData)
    nuevoAvisoMutate.mutate(formData);
  };

  const Item = ({ item }) => {
    let contador = item.index + 1;

    return (
      <View
        style={[
          tw`justify-center flex-1`,
          { display: item.index === contadorImagenes ? null : "none" },
        ]}
      >
        <Image
          source={{ uri: item.item.uri }}
          style={[tw`w-full h-160`, { resizeMode: "contain" }]}
        />
        {images?.length > 1 ? (
          <>
            {item.index >= 1 ? (
              <TouchableOpacity
                style={tw`left-5 absolute z-50`}
                onPress={() => setContadorImagenes(contadorImagenes - 1)}
              >
                <Ionicons
                  name="ios-arrow-back-circle-sharp"
                  size={35}
                  color="#FFF"
                />
              </TouchableOpacity>
            ): null}
            {images?.length !== contador ? (
              <TouchableOpacity
                style={tw`right-5 absolute z-50`}
                onPress={() => setContadorImagenes(contadorImagenes + 1)}
              >
                <Ionicons
                  name="ios-arrow-forward-circle-sharp"
                  size={35}
                  color="#FFF"
                />
              </TouchableOpacity>
            ) : null}
          </>
        ) : null}
      </View>
    );
  };

  return (
    <View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisibleAvisoDesktop}
      >
        <View
          style={[
            tw`flex-1 justify-center z-40`,
            { backgroundColor: "#000000bf" },
          ]}
        >
          <View
            style={[
              tw`mx-50 items-center py-3`,
              {
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                backgroundColor: "#FFF",
                display: images?.length > 0 ? "flex" : "none",
              },
            ]}
          >
            <Text>Crea un nuevo aviso</Text>
          </View>
          <View
            style={
              images?.length > 0
                ? tw`flex-row mx-50`
                : tw`flex-row mx-150 h-200`
            }
          >
            {!sinImagen ? (
              <View
                style={[
                  tw`flex-1 justify-center`,
                  {
                    borderBottomLeftRadius: 20,
                    borderBottomRightRadius: images?.length > 0 ? 0 : 20,
                    borderTopLeftRadius: images?.length > 0 ? 0 : 20,
                    borderTopRightRadius: images?.length > 0 ? 0 : 20,
                    backgroundColor: images?.length > 0 ? "#000" : "#FFF",
                  },
                ]}
              >
                {images?.length > 0 ? (
                  <View>
                    <FlatList
                      data={images}
                      keyExtractor={(item) => item.uri}
                      renderItem={(item, index) => (
                        <Item item={item} index={index} />
                      )}
                    />
                  </View>
                ) : (
                  <View
                    style={[
                      tw`flex-1 items-center`,
                      {
                        borderBottomLeftRadius: 20,
                        borderBottomRightRadius: 20,
                      },
                    ]}
                  >
                    <View style={tw`flex-2`}>
                      <Text
                        style={tw`text-xl mt-5 font-semibold text-gray-600`}
                      >
                        Crear Publicación
                      </Text>
                    </View>
                    <View style={tw`items-center flex-8 justify-center`}>
                      <Ionicons
                        name="ios-images-outline"
                        size={300}
                        color={Colores.primaryLight}
                      />
                    </View>
                    <View style={tw`flex-4 items-center`}>
                      <TouchableOpacity
                        onPress={pickImages}
                        foreground={true}
                        style={[
                          tw`px-10 py-3`,
                          {
                            backgroundColor: Colores.primary,
                            borderRadius: 16,
                          },
                        ]}
                      >
                        <Text style={tw`text-white`}>
                          Seleccionar desde la computadora
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        foreground={true}
                        style={[
                          tw`px-5 py-3 mt-5`,
                          { backgroundColor: "#aeaeae", borderRadius: 16 },
                        ]}
                        onPress={() => setSinImagen(true)}
                      >
                        <Text style={tw`text-white text-center`}>
                          Sin imagen
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
              </View>
            ): null}

            <View
              style={[
                tw`flex-1 h-180 w-80 border-gray-300 bg-white hidden`,
                {
                  borderBottomRightRadius: 20,
                  borderTopLeftRadius: sinImagen ? 20 : 0,
                  borderBottomLeftRadius: sinImagen ? 20 : 0,
                  borderTopRightRadius: sinImagen ? 20 : 0,
                  borderTopWidth: 1,
                  display: images?.length > 0 || sinImagen ? "flex" : "none",
                },
              ]}
            >
              <View style={tw`flex-row p-3`}>
                <View style={tw`justify-center`}>
                  <Image
                    source={{ uri: user?.foto }}
                    style={tw`h-10 w-10 rounded-full`}
                  />
                </View>
                <View style={tw`justify-center`}>
                  <Text
                    style={tw`ml-3 font-semibold capitalize`}
                  >{`${user.nombre} ${user.apellido} - ${user.puesto}`}</Text>
                </View>
              </View>

              <View style={tw`px-3`}>
                <Controller
                  control={control}
                  rules={{
                    required: "Ingrese un titulo",
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      style={[
                        tw` w-full px-3 py-2.5 text-base font-normal text-gray-700 shadow-sm border border-gray-300`,
                        { borderRadius: 50 },
                      ]}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      placeholder="Titulo"
                      value={value}
                    />
                  )}
                  name="titulo"
                />
                {errors?.titulo ? (
                  <MensajeError message={errors.titulo?.message} />
                ) : null}
              </View>

              <View style={tw`px-3`}>
                <Controller
                  control={control}
                  rules={{
                    required: "Ingrese el texto",
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      style={[
                        tw` w-full h-50 px-3 mt-2 py-1.5 text-base font-normal text-gray-700 border border-gray-300 shadow-sm`,
                        { borderRadius: 10 },
                      ]}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      placeholder="Descripción"
                      multiline={true}
                      value={value}
                    />
                  )}
                  name="texto"
                />
                {errors?.texto ? (
                  <MensajeError message={errors.texto?.message} />
                ): null}
              </View>
              <View style={tw`px-3`}>
                <Controller
                  control={control}
                  rules={{ validate: validateURL }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      style={[
                        tw`w-full px-3 mt-4 py-2.5 text-base font-normal text-gray-700 shadow-sm border border-gray-300`,
                        { borderRadius: 50 },
                      ]}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      placeholder="Video url"
                      value={value}
                    />
                  )}
                  name="url"
                />
                {errors?.url && <MensajeError message={errors.url?.message} />}
              </View>

              <View style={tw`px-3`}>
                <Controller
                  control={control}
                  defaultValue={comentariosActivo}
                  name="comentariosActivo"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View style={tw`flex-row flex my-5`}>
                      <Switch
                        trackColor={{ false: "#767577", true: "#81b0ff" }}
                        thumbColor={value ? "#f5dd4b" : "#f4f3f4"}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={(value) => {
                          toggleComentariosActivoSwitch;
                          onChange(value ? 1 : 0);
                        }}
                        value={value}
                        style={tw`mt-1`}
                      />
                      <Text style={tw`ml-3 text-gray-700 text-lg`}>
                        Activar comentarios
                      </Text>
                    </View>
                  )}
                />
              </View>

              <View style={tw`hidden`}>
                <Controller
                  control={control}
                  defaultValue={esTemporal}
                  name="esTemporal"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View style={tw`flex-row flex my-5`}>
                      <Switch
                        trackColor={{ false: "#767577", true: "#81b0ff" }}
                        thumbColor={value ? "#f5dd4b" : "#f4f3f4"}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={(value) => {
                          toggleEsTemporalSwitch;
                          onChange(value ? 1 : 0);
                        }}
                        value={value}
                        style={tw`mt-1`}
                      />
                      <Text style={tw`ml-3 text-gray-700 text-lg`}>
                        Subir como historia
                      </Text>
                    </View>
                  )}
                />
              </View>

              <View style={tw`mt-3 px-3`}>
                <MultiSelectEmpresas />
              </View>

              {nuevoAvisoMutate.isLoading && !nuevoAvisoMutate.isError ? (
                <ActivityIndicator size="small" color={Colores.primary} />
              ) : (
                <View style={tw`items-center justify-center mb-10 flex-row`}>
                  <TouchableOpacity
                    style={[
                      tw`mt-4 px-5 py-2.5 shadow-md mr-2`,
                      { backgroundColor: Colores.primary, borderRadius: 15 },
                    ]}
                    onPress={() => {
                      setImages([]), setSinImagen(false);
                    }}
                  >
                    <Ionicons name="arrow-back" size={20} color="white" />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[
                      tw`mt-4 px-20 py-2.5 shadow-md`,
                      { backgroundColor: Colores.primary, borderRadius: 15 },
                    ]}
                    onPress={handleSubmit(onSubmit)}
                  >
                    <Text style={tw`text-white text-center`}>Compartir</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </View>
          <TouchableOpacity
            style={tw`absolute top-5 right-5`}
            onPress={() => {
              setModalVisibleAvisoDesktop(false),
                setImages(null),
                setSinImagen(false);
            }}
          >
            <AntDesign name="close" size={24} color="#FFF" />
          </TouchableOpacity>
        </View>
      </Modal>
    </View>
  );
};

export default CrearAvisoModalDesktop;
